<template>
  <el-dialog
    id="AboutDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form ref="AboutForm" @submit.native.prevent="onSubmit">
      <el-row>
        <el-col style="text-align:center;"
          ><h1>{{ $t("app.name") }}</h1></el-col
        >
      </el-row>
      <el-row>
        <el-col style="text-align:center;"
          ><h3>Version: {{ version }}</h3></el-col
        >
      </el-row>
      <el-row>
        <el-col style="text-align:center;"
          ><h4>Server version: {{ serverVersion }}</h4></el-col
        >
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";

  export default {
    name: "About",
    data() {
      var that = this;
      var defaultValidateRule = { required: true, message: "Це поле є необхідним" };
      return {
        loading: false,
        dialogShow: false,
        title: "Про сервіс",
      };
    },

    methods: {
      onCommit() {
        this.dialogShow = false;
      },
      onShow() {
        this.$refs.AboutForm.resetFields();
        this.loading = false;
      },
    },
    created() {
      bus.$on("forms.about.show", (data) => {
        this.loading = true;
        this.title = (data && data.title) || this.title;
        this.dialogShow = true;
      });
      bus.$on("forms.about.hide", () => {
        this.dialogShow = false;
      });
    },
    computed: {
      version() {
        return this.getInfo().version;
      },
      serverVersion() {
        return this.$store.getters["main/serverInfo"]().build;
      },
    },
  };
</script>
<style lang="scss">
  #AboutDialog {
    .el-dialog {
      max-height: 340px !important;
    }
    .button-in-form {
      margin-bottom: 16px;
    }
  }
</style>
